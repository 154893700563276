<template>
  <pmis-content-box :loading="loading">
    <template v-slot:header-left>
      <iui-select :items="searchSeItems" :value.sync="searchSe" defaultCd="S"></iui-select>
      <iui-text type="search" :value.sync="searchText" @enter="onSearch" />
      <iui-button @click="onSearch">검색</iui-button>
    </template>

    <ib-sheet :uid="_uid" :options="Options" :loadSearchData="loadSearchData" @loadSheet="loadSheet" />
  </pmis-content-box>
</template>

<script>
import options from './sheetOption/PrjUserListSheet.js';
import {selectPrjAuthList} from '../api/prjAuth.js';
export default {
  data() {
    return {
      sheet: undefined,
      Options: $addColCheckbox(options(this), 1, 'auth'),
      loadSearchData: [],
      searchSeItems: [
        {text: '사용자ID', value: 'id'},
        {text: '이름', value: 'name'},
      ],
      searchText: '',
      searchSe: '',
      loading: false,
    };
  },
  beforeCreate() {},
  created() {
    this.addEvent([
      {name: 'PrjUserList_selectPrjAuthList', func: this.onSearch},
      {name: 'PrjUserList_getSaveJson', func: this.getSaveJson},
    ]);
  },
  methods: {
    loadSheet(sheet) {
      this.sheet = sheet;
    },
    async onSearch() {
      this.loading = true;
      const response = await selectPrjAuthList();
      this.loadSearchData = response.data;
      this.loading = false;
    },
    getSaveJson(callback) {
      if (callback) {
        callback(this.sheet.getSaveJson(0).data);
      }
    },
  },
};
</script>

<style></style>
