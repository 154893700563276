<template>
  <pmis-content-box>
    <template v-slot:header-left>
      <iui-text type="search" @change="searchTxt = $event.target.value" @enter="onSearch" />
      <iui-button value="검색" @click="onSearch" />
      <iui-checkbox-group
        :items="[{label: '종료현장포함', value: 'Y'}]"
        @change="
          event => {
            closeAt = event.target.checked ? event.target.value : 'N';
            onSearch();
          }
        "
      />
    </template>
    <ib-sheet
      :uid="_uid"
      :options="Options"
      :loadSearchData="loadSearchData"
      :events="{
        onSearchFinish,
        onClick,
        onRenderFirstFinish,
      }"
      @loadSheet="loadSheet"
    />
  </pmis-content-box>
</template>

<script>
/**
 * 공정사진조회 현장목록
 *
 * */
import options from './sheetOption/PrjListSheet.js';
import {selectPjtList} from '../api/photo.js';
export default {
  beforeCreate() {
    $mapGetters(this, ['detailInfo']);
    $mapMutations(this, ['setSearchInfo', 'initSearchInfo', 'initDetailInfo', 'initPhotos']);
  },
  created() {
    this.addEvent([{name: 'prjListOnSearch', func: this.onSearch}]);
  },
  data() {
    return {
      searchTxt: '',
      closeAt: '',
      Options: options(this),
      loadSearchData: [],
      focusPrjCd: '',
    };
  },
  methods: {
    onSearch(focusPrjCd = '') {
      this.focusPrjCd = focusPrjCd;
      let param = {
        pgmCd: this.pgmCd,
        searchTxt: this.searchTxt,
        closeAt: this.closeAt,
        auth: true,
      };
      selectPjtList(param).then(response => {
        if (response.status == 200) {
          this.sheet.loadSearchData(response.data);
        }
      });
    },
    onSearchFinish(e) {
      let searchRow = this.focusPrjCd
        ? e.sheet.getDataRows().find(row => row.prjCd == this.focusPrjCd)
        : e.sheet.getFirstRow();

      if (searchRow) {
        this.sheet.focus(searchRow);
        this.setSearchInfo(searchRow);
        this.callEvent({name: 'photoInfoListOnSearch', param: this.detailInfo});
      } else {
        this.initSearchInfo();
        this.initDetailInfo();
        this.initPhotos();
      }
    },
    onClick(e) {
      if (e.row.Kind == 'Data') {
        this.setSearchInfo(e.row);
        this.callEvent({name: 'photoInfoListOnSearch', param: this.detailInfo});
      }
    },
    onRenderFirstFinish() {
      this.onSearch();
    },
    loadSheet(sheet) {
      this.sheet = sheet;
      this.sheetUtil = sheet.util;
    },
  },
};
</script>

<style></style>
