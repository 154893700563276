import { render, staticRenderFns } from "./PrjAllList.vue?vue&type=template&id=55783853&scoped=true&"
import script from "./PrjAllList.vue?vue&type=script&lang=js&"
export * from "./PrjAllList.vue?vue&type=script&lang=js&"
import style0 from "./PrjAllList.vue?vue&type=style&index=0&id=55783853&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55783853",
  null
  
)

export default component.exports