<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col>
          <pmis-tab-box>
            <template #title>손익현황</template>
            <pmis-content-box :loading="loading">
              <template #header-left>
                <iui-datepicker
                  type="month"
                  label="작업년월"
                  :value="searchInfo.reqYm"
                  @update:value="reqYm_update"
                  required
                  error-message="필수입력"
                />
                <i class="prev-arrow" @click.prevent.self @click="prev" />
                <i class="post-arrow" @click.prevent.self @click="next" />
                <span class="ml5"></span>
                <iui-text type="search" label="현장" :value.sync="searchInfo.searchNm" @enter="onSearch" />
                <iui-button @click="onSearch" value="검색" />
              </template>
              <template #header-right>
                <iui-amount-unit :amount-unit="amountUnit" @change="onAmountChange" />
              </template>
              <ib-sheet
                :uid="_uid"
                :options="Options"
                :loadSearchData="loadSearchData"
                @loadSheet="sheet = $event"
                :events="{onSearchFinish, onDblClick}"
              />
            </pmis-content-box>
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import options from './sheetOptions/ByProjectSheet.js';
import {selectConProfitStateByPrjList} from '../api/incomestatus.js';
import {selectMonCloseList} from '@/view/closing/expitmCloseRegist/api/expitmCloseRegist.js';
import IuiAmountUnit from '@/components/common/IuiAmountUnit.vue';

export default {
  components: {IuiAmountUnit},
  data() {
    return {
      sheet: undefined,
      Options: options(this),
      loadSearchData: [],
      searchInfo: {
        searchNm: '',
        reqYm: '',
        auth: true,
      },
      loading: false,
      searchIndex: -1,
      searchDatas: [],
      responseData: {
        sheet: [],
      },
      amountUnit: '',
    };
  },
  created() {
    this.amountUnit = this.userInfo?.amountUnit
      ? this.userInfo.amountUnit
      : this.$store.state.code2.codeAlias.AMOUNT_UNIT_ONE_THOUSAND_WON.code;
    this.onInitSearch();
    this.addFuncSearch(this.onSearch);
  },
  methods: {
    onSearchFinish() {
      this.sheetMerge();
    },
    onDblClick(e) {
      if (e.kind === 'Data' && e.col === 'prjNm') {
        this.$store.commit('setPrjCd', e.row.prjCd);
        this.goMenu('INCOME_STATUS_BY_MID', {prjCd: e.row.prjCd, reqYm: this.searchInfo.reqYm});
      }
    },
    _numberFormat(value) {
      let n = Number(value);
      if (!Number.isNaN(n)) {
        return $_numberFormat(n);
      } else {
        return value;
      }
    },
    sheetMerge() {
      let Rows = this.sheet.getDataRows();
      let key = '';
      let sRow;
      let eRow;
      let mergeInfo = {};
      Rows.forEach(function(r) {
        if (key != r.prjCd) {
          key = r.prjCd;
          sRow = r.id;
          mergeInfo[sRow] = sRow;
        } else {
          eRow = r.id;
          mergeInfo[sRow] = eRow;
        }
      });

      for (let m in mergeInfo) {
        this.sheet.setMergeRange(this.sheet.getRowById(m), 'prjNm', this.sheet.getRowById(mergeInfo[m]), 'prjNm');
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'contBudgetAmt',
          this.sheet.getRowById(mergeInfo[m]),
          'contBudgetAmt'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'execBudgetAmt',
          this.sheet.getRowById(mergeInfo[m]),
          'execBudgetAmt'
        );
        this.sheet.setMergeRange(
          this.sheet.getRowById(m),
          'execBudgetRate',
          this.sheet.getRowById(mergeInfo[m]),
          'execBudgetRate'
        );
      }
    },
    async onInitSearch() {
      const response = await selectMonCloseList({});
      this.searchDatas = response.data;
      this.searchIndex = this.searchDatas.length - 1;
      this.setSearchData();
    },
    prev() {
      if (this.searchIndex < 1) {
        this.$alert({title: '작업년월', message: '이전 작업년월이 없습니다.'});
        return;
      }
      this.searchIndex--;
      this.setSearchData();
    },
    next() {
      if (this.searchIndex >= this.searchDatas.length - 1) {
        this.$alert({title: '작업년월', message: '다음 작업년월이 없습니다.'});
        return;
      }
      this.searchIndex++;
      this.setSearchData();
    },
    setSearchData() {
      const searchData = this.searchDatas[this.searchIndex];
      this.searchInfo.reqYm = searchData?.closeMm || '';
      this.onSearch();
    },
    async onSearch() {
      this.loading = true;
      try {
        const response = await selectConProfitStateByPrjList({...this.searchInfo});
        this.responseData.sheet = response.data;
        this.convertPriceUnit();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    reqYm_update(e) {
      this.searchInfo.reqYm = e;
      if (e) this.onSearch();
    },
    goByMid(e) {
      this.$store.commit('setPrjCd', e.row.prjCd);
      const param = {
        prjCd: e.row.prjCd,
        reqYm: this.searchInfo.reqYm,
      };
      this.goMenu('INCOME_STATUS_BY_MID', param);
    },
    onAmountChange(value) {
      this.amountUnit = value;
      this.convertPriceUnit();
    },
    convertPriceUnit() {
      this.loadSearchData = $_priceUnitFormat(this.amountUnit, this.responseData.sheet);
    },
  },
};
</script>
