<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjList />
        </i-col>
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>사용자목록</template>
            <PrjUserList />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
  </pmis-page>
</template>

<script>
import PrjUserList from './components/PrjUserList.vue';
import PrjList from '@/view/Project/components/PrjList.vue';
import {savePrjAuthList} from './api/prjAuth.js';

export default {
  components: {PrjUserList, PrjList},

  data() {
    return {};
  },
  created() {
    this.canNew = false;
    this.addFuncPrj(this.onSearch);
    this.addFuncSave(this.onSave);
  },
  methods: {
    onSearch() {
      this.callEvent({name: 'PrjUserList_selectPrjAuthList'});
    },
    onSave() {
      this.callEvent({
        name: 'PrjUserList_getSaveJson',
        param: data => {
          console.log(data);
          const prjAuthList = data.map(el => {
            el.auth = el.auth == 1 ? true : false;
            return {
              auth: el.auth,
              empNo: el.empNo,
              prjCd: this.prjCd,
            };
          });
          savePrjAuthList({prjAuthList}).then(response => {
            this.onSearch();
          });
        },
      });
    },
  },
};
</script>

<style></style>
